<script lang="tsx">
import type { ComponentInstance, ComputedRef, PropType, SlotsType } from 'vue'
import type { ApiResponse } from '@composable-api/api.response'
import type { ComponentOverrideOptions } from '@core-types/components'

export type BaseUiPaginationProps<T> = {
    /**
     * What html tag to use for the buttons.
     * This prop is not reactive. The tag is only evaluated at render time.
     * @default 'button'
     */
    buttonTag?: 'link' | 'button'
    /**
     * A description of what the pagination component is for.
     */
    ariaLabel: string
    /**
     * Whether the pagination component is disabled.
     * @default false
     */
    disabled?: boolean
    /**
     * Whether the pagination component is loading.
     * @default false
     */
    loading?: boolean
    /**
     * Whether to always show the first and the last page along with the ellipsis.
     */
    showEdges?: boolean
    /**
     * The number of sibling pages to show on each side of the current page.
     * @default 1
     */
    siblingCount?: number
    /**
     * The meta data for the paginated data.
     * This will get overwritten if the current page and number of pages are provided.
     */
    meta?: ReturnType<ApiResponse<any>['getMeta']> | null
    /**
     * The page that is currently selected.
     * Will be overridden by the `v-model` if present.
     */
    currentPage?: number | null
    /**
     * The total number of pages.
     * Will be overridden by `v-model:pagination` if present.
     */
    numberOfPages?: number | null
    /**
     * Whether to hide the next and previous page buttons.
     * @default false
     */
    hideStepButtons?: boolean
    /**
     * Whether to hide the first and last page buttons.
     * @default false
     */
    hideJumpButtons?: boolean
    /**
     * Whether to hide the navigation buttons when they are disabled.
     * @default false
     */
    hideDisabledNavigationButtons?: boolean
    /**
     * Whether to hide the pagination buttons when there is only one page.
     * @default false
     */
    hideWhenSinglePage?: boolean
    /**
     * A generator function that returns the URL for a given page.
     * @param page The page number to generate the URL for.
     */
    url?: (page: number) => string | null | undefined
    alignEnd?: boolean
}

type BaseUiPaginationSlots<T> = {
    previousPageButton: { isDisabled: ComputedRef<boolean> }
    nextPageButton: { isDisabled: ComputedRef<boolean> }
    firstPageButton: { isDisabled: ComputedRef<boolean> }
    lastPageButton: { isDisabled: ComputedRef<boolean> }
    ellipsis: { isLeading: boolean, isTrailing: boolean }
    paginationButton: { page: number, isDisabled: boolean }
}

type ComponentOptions = {

}

export function defineComponentBaseUiPagination<T>(_options?: ComponentOverrideOptions<ComponentOptions, BaseUiPaginationProps<T>, BaseUiPaginationSlots<T>>) {
    return defineComponent(
        (props: BaseUiPaginationProps<T>, ctx) => {

            const { t } = useI18n({ useScope: 'global' })

            const _numberOfPages = computed<number>(() => props.numberOfPages ?? props.meta?.total_pages ?? 0)
            const _siblingCount = computed<number>(() => props.siblingCount ?? 1)

            const _page = ref<number>(props.currentPage ?? props.meta?.page ?? 0)

            const _pageSrc = computed(() => props.currentPage ?? props.meta?.page)
            watch(_pageSrc, (newPage) => {
                if (newPage === null || newPage === undefined) return
                _page.value = newPage
            })
            const _firstPage = ref<number>(1)
            const _lastPage = computed<number>(() => _numberOfPages.value)

            const isDisabled = computed<boolean>(() => (props.disabled || props.loading || _numberOfPages.value < 1))
            const isLoading = computed<boolean>(() => !!props.loading)
            const page = computed<number>(() => _page.value)

            /**
             * The page buttons to render.
             * When the value is `null`, an ellipsis should be rendered.
             */
            const _pagesToRender = computed<Array<number | null>>(() => shouldRenderButtons.value
                ? getRange(
                    page.value,
                    _numberOfPages.value,
                    _siblingCount.value,
                    props.showEdges ?? false
                )
                : []
            )

            const shouldRenderButtons = computed(() => !props.hideWhenSinglePage || _numberOfPages.value > 1)

            const _isFirstPageBtnVisible = computed<boolean>(() => shouldRenderButtons.value && (!props.hideJumpButtons && (!props.hideDisabledNavigationButtons || !areFirstAndPreviousPageButtonsDisabled.value)))
            const _isPreviousPageBtnVisible = computed<boolean>(() => shouldRenderButtons.value && (!props.hideStepButtons && (!props.hideDisabledNavigationButtons || !areFirstAndPreviousPageButtonsDisabled.value)))
            const _isNextPageBtnVisible = computed<boolean>(() => shouldRenderButtons.value && (!props.hideStepButtons && (!props.hideDisabledNavigationButtons || !areLastAndNextPageButtonsDisabled.value)))
            const _isLastPageBtnVisible = computed<boolean>(() => shouldRenderButtons.value && (!props.hideJumpButtons && (!props.hideDisabledNavigationButtons || !areLastAndNextPageButtonsDisabled.value)))

            const areFirstAndPreviousPageButtonsDisabled = computed<boolean>(() => isDisabled.value || page.value === _firstPage.value)
            const areLastAndNextPageButtonsDisabled = computed<boolean>(() => isDisabled.value || page.value === _lastPage.value)

            const tag = computed(() => props.url ? 'link' : props.buttonTag ?? 'button')
            // Dynamic Component Resolution (only at render time)
            const PageBtnComponent = {
                'link': resolveComponent('NuxtLink') as ComponentInstance<any>,
                'button': 'button',
            }[tag.value]

            const FirstAndPreviousPageBtnComponent = computed(() => areFirstAndPreviousPageButtonsDisabled.value ? 'span' : PageBtnComponent)
            const LastAndNextPageBtnComponent = computed(() => areLastAndNextPageButtonsDisabled.value ? 'span' : PageBtnComponent)


            function goToPage(pageNum: number) {
                if (isDisabled.value) return
                if (pageNum === page.value) return
                if (pageNum < _firstPage.value || pageNum > _numberOfPages.value) return

                _page.value = pageNum
                ctx.emit('change', _page.value)
            }

            function goToNextPage() {
                if (isDisabled.value) return
                if (page.value === _lastPage.value) return
                _page.value += 1
                ctx.emit('change', _page.value)
            }

            function goToPreviousPage() {
                if (isDisabled.value) return
                if (page.value === _firstPage.value) return
                _page.value -= 1
                ctx.emit('change', _page.value)
            }

            function goToFirstPage() {
                if (isDisabled.value) return
                if (page.value === _firstPage.value) return
                _page.value = _firstPage.value
                ctx.emit('change', _page.value)
            }

            function goToLastPage() {
                if (isDisabled.value) return
                if (page.value === _lastPage.value) return
                _page.value = _numberOfPages.value
                ctx.emit('change', _page.value)
            }

            function getUrlForPage(pageNum: number): string | undefined {
                if (isDisabled.value) return undefined
                if (pageNum < _firstPage.value || pageNum > _lastPage.value) return undefined
                return props.url?.(pageNum) ?? undefined
            }


            // TODO: add aria-labels to specific links "Go to page {num}" / "Current page, Page {num}"
            // TODO: make the nav items links first & add an option to change them into buttons
            return () => (
                <nav role="navigation" aria-label={props.ariaLabel} class={['sim-pagination', {
                    'sim-pagination--disabled': isDisabled.value,
                    'sim-pagination--loading': isLoading.value,
                    'sim-pagination--end': props.alignEnd,
                }]}>
                    <ul class="sim-pagination__wrapper">
                        { /* First Page Button */ }
                        {
                            _isFirstPageBtnVisible.value && (
                                <li>
                                    <FirstAndPreviousPageBtnComponent.value {...{
                                        'class': ['sim-pagination__btn', { 'sim-pagination__btn--disabled': areFirstAndPreviousPageButtonsDisabled.value }],
                                        'aria-label': t('_core_simploshop.labels.go_to_first_page'),
                                        ...(tag.value === 'button'
                                            ? {
                                                'type': 'button',
                                                'onClick': goToFirstPage,
                                                'disabled': areFirstAndPreviousPageButtonsDisabled.value,
                                            }
                                            : {
                                                'to': getUrlForPage(_firstPage.value),
                                            }),
                                    }}>
                                        {renderSlot(ctx.slots.firstPageButton, _options?.slots?.firstPageButton, { isDisabled }, (
                                            <span>
                                                &lt;&lt;
                                            </span>
                                        ))}
                                    </FirstAndPreviousPageBtnComponent.value>
                                </li>
                            )}

                        { /* Previous Page Button */ }
                        {_isPreviousPageBtnVisible.value && (
                            <li>

                                <FirstAndPreviousPageBtnComponent.value {...{
                                    'class': ['sim-pagination__btn', { 'sim-pagination__btn--disabled': areFirstAndPreviousPageButtonsDisabled.value }],
                                    'aria-label': t('_core_simploshop.labels.go_to_previous_page'),
                                    ...(tag.value === 'button'
                                        ? {
                                            'type': 'button',
                                            'onClick': goToPreviousPage,
                                            'disabled': areFirstAndPreviousPageButtonsDisabled.value,
                                        }
                                        : {
                                            'to': getUrlForPage(page.value - 1),
                                        }),
                                }}>
                                    {renderSlot(ctx.slots.previousPageButton, _options?.slots?.previousPageButton, { isDisabled }, (
                                        <span>
                                            &lt;
                                        </span>
                                    ))}
                                </FirstAndPreviousPageBtnComponent.value>

                            </li>
                        )}

                        { /* Pagination Buttons */ }
                        {
                            _pagesToRender.value.map(pageNum => (
                                <li>
                                    {
                                        pageNum === null
                                            ? (
                                                <div class="sim-pagination__btn sim-pagination__btn--ellipsis">
                                                    {renderSlot(ctx.slots.ellipsis, _options?.slots?.ellipsis, {
                                                        isLeading: false,
                                                        isTrailing: false,
                                                    }, (
                                                        <span>...</span>
                                                    ))}
                                                </div>
                                            )
                                            : (
                                                <PageBtnComponent {...{
                                                    'class': ['sim-pagination__btn', {
                                                        'sim-pagination__btn--active': pageNum === page.value,
                                                        'sim-pagination__btn--disabled': isDisabled.value,
                                                    }],
                                                    'aria-label': t(pageNum === page.value ? '_core_simploshop.labels.current_page' : '_core_simploshop.labels.go_to_page', [pageNum]),
                                                    ...(tag.value === 'button'
                                                        ? {
                                                            'type': 'button',
                                                            'onClick': () => goToPage(pageNum),
                                                            'disabled': isDisabled.value,
                                                        }
                                                        : {
                                                            'to': getUrlForPage(pageNum),
                                                        }),
                                                }}>
                                                    {renderSlot(ctx.slots.paginationButton, _options?.slots?.paginationButton, {
                                                        page: pageNum,
                                                        isDisabled: false,
                                                    }, (
                                                        <span aria-hidden="true">
                                                            {pageNum}
                                                        </span>
                                                    ))}
                                                </PageBtnComponent>
                                            )
                                    }
                                </li>
                            ))
                        }

                        { /* Next Page Button */ }
                        {_isNextPageBtnVisible.value && (
                            <li>
                                <LastAndNextPageBtnComponent.value {...{
                                    'class': ['sim-pagination__btn', { 'sim-pagination__btn--disabled': areLastAndNextPageButtonsDisabled.value }],
                                    'aria-label': t('_core_simploshop.labels.go_to_next_page'),
                                    ...(tag.value === 'button'
                                        ? {
                                            'type': 'button',
                                            'onClick': goToNextPage,
                                            'disabled': areLastAndNextPageButtonsDisabled.value,
                                        }
                                        : {
                                            'to': getUrlForPage(page.value + 1),
                                        }),
                                }}>
                                    {renderSlot(ctx.slots.nextPageButton, _options?.slots?.nextPageButton, { isDisabled }, (
                                        <span>
                                            &gt;
                                        </span>

                                    ))}
                                </LastAndNextPageBtnComponent.value>
                            </li>
                        )}

                        { /* Last Page Button */ }
                        {_isLastPageBtnVisible.value && (
                            <li>
                                <LastAndNextPageBtnComponent.value {...{
                                    'class': ['sim-pagination__btn', { 'sim-pagination__btn--disabled': areLastAndNextPageButtonsDisabled.value }],
                                    'aria-label': t('_core_simploshop.labels.go_to_last_page'),
                                    ...(tag.value === 'button'
                                        ? {
                                            'type': 'button',
                                            'onClick': goToLastPage,
                                            'disabled': areLastAndNextPageButtonsDisabled.value,
                                        }
                                        : {
                                            'to': getUrlForPage(_lastPage.value),
                                        }),
                                }}>
                                    {renderSlot(ctx.slots.lastPageButton, _options?.slots?.lastPageButton, { isDisabled }, (
                                        <span>
                                            &gt;&gt;
                                        </span>
                                    ))}
                                </LastAndNextPageBtnComponent.value>
                            </li>
                        )}
                    </ul>
                </nav>
            )

        },
        {
            props: {
                buttonTag: {
                    type: String as PropType<BaseUiPaginationProps<T>['buttonTag']>,
                    required: _options?.props?.buttonTag?.required ?? false,
                    default: _options?.props?.buttonTag?.default ?? 'button',
                },
                ariaLabel: {
                    type: String as PropType<BaseUiPaginationProps<T>['ariaLabel']>,
                    required: _options?.props?.ariaLabel?.required ?? true,
                    default: _options?.props?.ariaLabel?.default,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['disabled']>,
                    required: _options?.props?.disabled?.required ?? false,
                    default: _options?.props?.disabled?.default ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['loading']>,
                    required: _options?.props?.loading?.required ?? false,
                    default: _options?.props?.loading?.default ?? false,
                },
                showEdges: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['showEdges']>,
                    required: _options?.props?.showEdges?.required ?? false,
                    default: _options?.props?.showEdges?.default ?? true,
                },
                siblingCount: {
                    type: Number as PropType<BaseUiPaginationProps<T>['siblingCount']>,
                    required: _options?.props?.siblingCount?.required ?? false,
                    default: _options?.props?.siblingCount?.default ?? 1,
                },
                meta: {
                    type: Object as PropType<BaseUiPaginationProps<T>['meta']>,
                    required: _options?.props?.meta?.required ?? false,
                    default: _options?.props?.meta?.default,
                },
                currentPage: {
                    type: Number as PropType<BaseUiPaginationProps<T>['currentPage']>,
                    required: _options?.props?.currentPage?.required ?? false,
                    default: _options?.props?.currentPage?.default,
                },
                numberOfPages: {
                    type: Number as PropType<BaseUiPaginationProps<T>['numberOfPages']>,
                    required: _options?.props?.numberOfPages?.required,
                    default: _options?.props?.numberOfPages?.default,
                },
                hideStepButtons: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['hideStepButtons']>,
                    required: _options?.props?.hideStepButtons?.required ?? false,
                    default: _options?.props?.hideStepButtons?.default ?? false,
                },
                hideJumpButtons: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['hideJumpButtons']>,
                    required: _options?.props?.hideJumpButtons?.required ?? false,
                    default: _options?.props?.hideJumpButtons?.default ?? false,
                },
                hideDisabledNavigationButtons: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['hideDisabledNavigationButtons']>,
                    required: _options?.props?.hideDisabledNavigationButtons?.required ?? false,
                    default: _options?.props?.hideDisabledNavigationButtons?.default ?? false,
                },
                hideWhenSinglePage: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['hideWhenSinglePage']>,
                    required: _options?.props?.hideWhenSinglePage?.required ?? false,
                    default: _options?.props?.hideWhenSinglePage?.default ?? false,
                },
                url: {
                    type: Function as PropType<BaseUiPaginationProps<T>['url']>,
                    required: _options?.props?.url?.required ?? false,
                    default: _options?.props?.url?.default,
                },
                alignEnd: {
                    type: Boolean as PropType<BaseUiPaginationProps<T>['alignEnd']>,
                    required: _options?.props?.alignEnd?.required ?? false,
                    default: _options?.props?.alignEnd?.default,
                },
            },
            slots: Object as SlotsType<BaseUiPaginationSlots<T>>,
            emits: {
                change: (page: number) => true,
            },
        }
    )
}

export default defineComponentBaseUiPagination()

// =====================================================================================================================
// UTILS (from radix-vue)
// reference https://github.com/chakra-ui/zag/blob/main/packages/machines/pagination/src/pagination.utils.ts
// =====================================================================================================================

function range(start: number, end: number) {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
}

export function getRange(currentPage: number, pageCount: number, siblingCount: number, showEdges: boolean): Array<number | null> {
    const firstPageIndex = 1
    const lastPageIndex = pageCount

    const leftSiblingIndex = Math.max(currentPage - siblingCount, firstPageIndex)
    const rightSiblingIndex = Math.min(currentPage + siblingCount, lastPageIndex)

    const showLeftEllipsis = leftSiblingIndex > firstPageIndex + 2
    const showRightEllipsis = rightSiblingIndex < lastPageIndex - 2

    if (showEdges) {
        /**
         * `2 * siblingCount + 5` explanation:
         * 2 * siblingCount for left/right siblings
         * 5 for 2x left/right ellipsis, 2x first/last page + 1x current page
         *
         * For some page counts (e.g. totalPages: 8, siblingCount: 2),
         * calculated max page is higher than total pages,
         * so we need to take the minimum of both.
         */
        const totalPageNumbers = Math.min(2 * siblingCount + 5, pageCount)

        const itemCount = totalPageNumbers - 2 // 2 stands for one ellipsis and either first or last page

        if (!showLeftEllipsis && showRightEllipsis) {
            const leftRange = range(1, itemCount)
            return [...leftRange, null, lastPageIndex]
        }

        if (showLeftEllipsis && !showRightEllipsis) {
            const rightRange = range(lastPageIndex - itemCount + 1, lastPageIndex)
            return [firstPageIndex, null, ...rightRange]
        }

        if (showLeftEllipsis && showRightEllipsis) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex)
            return [firstPageIndex, null, ...middleRange, null, lastPageIndex]
        }

        return range(firstPageIndex, lastPageIndex)
    } else {
        const itemCount = siblingCount * 2 + 1

        if (pageCount < itemCount) {
            return range(1, lastPageIndex)
        } else if (currentPage <= (siblingCount + 1)) {
            return range(firstPageIndex, itemCount)
        } else if ((pageCount - currentPage) <= siblingCount) {
            return range(pageCount - itemCount + 1, lastPageIndex)
        } else {
            return range(leftSiblingIndex, rightSiblingIndex)
        }
    }
}

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiPagination" as *;

</style>
