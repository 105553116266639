<script lang="tsx">
import { defineComponentBaseUiPagination } from '@core/app/components/base/ui/BaseUiPagination.vue'
import { IconChevron } from '#components'

export default defineComponentBaseUiPagination({
    props: {
        hideJumpButtons: {
            default: true,
        },
        hideDisabledNavigationButtons: {
            default: true,
        },
        hideWhenSinglePage: {
            default: true,
        },
    },
    slots: {
        previousPageButton: () => <IconChevron size="sm" rotate={90} />,
        nextPageButton: () => <IconChevron size="sm" rotate={-90} />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiPagination" as *;

@include button {
    color: $sim-c-primary-800;
    border-radius: $sim-border-radius-sm;
    transition: background-color $sim-trans-time-normal $sim-timing, color $sim-trans-time-normal $sim-timing;

    @include sim-text-normal;
    @include sim-font-medium;
    @include accessibility-focus-outline-offset;
}

@include button--hover {
    background-color: $sim-c-basic-200;
    transition-duration: $sim-trans-time-short;
}

@include button--active {
    background-color: $sim-c-primary-800;
    color: $sim-c-white;
}

@include button--active-hover {
    background-color: $sim-c-primary-400;
}

</style>
